import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./contact-heading";
import Form from "./contact-form";
import Map from "./contact-map";
import Estimate from "./contact-estimate";

const Contact = () => (
  <>
    <SEO
      title={ContentObject.Contact.meta.title}
      description={ContentObject.Contact.meta.description}
    />

    <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
      <section data-csweb="contact">
        <Heading />
        <Form />
        <Map />
        <Estimate />
      </section>
    </Layout>
  </>
);

export default Contact;
