import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import { DisplayLarge, ParagraphLarge } from "baseui/typography";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import { useTheme } from "../../contexts/theme";

const Estimate = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article
      data-csweb="contact-estimate"
      className={css({
        paddingTop: theme.sizing.scale200,
        paddingBottom: theme.sizing.scale200,
        [theme.breakpoints.mediaQueries.large]: {
          paddingTop: theme.sizing.scale500,
          paddingBottom: theme.sizing.scale100,
        },
      })}
    >
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale4800,
              paddingBottom: theme.sizing.scale4800,
              position: "relative",
              zIndex: "4",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale2400,
                paddingBottom: theme.sizing.scale2400,
              },
              [theme.breakpoints.mediaQueries.small]: {
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
              },
            })}
          >
            <DisplayLarge
              $style={{
                textAlign: "center",
                fontWeight: "400",
                [theme.breakpoints.mediaQueries.medium]: {
                  textAlign: "center",
                  ...theme.typography.DisplayMedium,
                  fontWeight: "bold",
                },
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.DisplayXSmall,
                },
              }}
            >
              <FormattedContentItem scope="Contact" accessor="estimate.lead" />
            </DisplayLarge>
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: theme.sizing.scale1400,
                marginBottom: theme.sizing.scale1400,
                [theme.breakpoints.mediaQueries.small]: {
                  flexDirection: "column",
                },
              })}
            >
              <Button
                kind={ContentObject.Contact.estimate.button.kind}
                label={ContentObject.Contact.estimate.button.label}
                onClick={() =>
                  navigate(ContentObject.Contact.estimate.button.to)
                }
              >
                <ContentItem
                  scope="Contact"
                  accessor={`estimate.button.title`}
                />
              </Button>
            </div>
            <ParagraphLarge
              $style={{
                marginTop: theme.sizing.scale1400,
                justifyContent: "center",
                textAlign: "center",
                fontWeight: "700",
              }}
            >
              <FormattedContentItem
                scope="Contact"
                accessor="estimate.subtext"
              />
            </ParagraphLarge>
          </div>
        </Cell>
        <Cell skip={5} span={4}>
          <div
            className={css({
              position: "relative",
              width: "100%",
              height: "100%",
              marginTop: "-380px",
            })}
          >
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                top: "0%",
                left: "0%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Estimate;
